:root {
    --text-colour: var(--colour-0);
    --primary-colour: var(--colour-46);
    --background-colour: var(--colour-1);
}

body {
    outline-color: var(--primary-colour);
    color: var(--text-colour);
    padding-bottom: 5rem;
}

html {
    background-color: var(--background-colour);
}

.rs-checkbox-wrapper [type='checkbox'] ~ .rs-checkbox-inner::before {
    border-color: var(--colour-74);
}

:hover.rs-checkbox-wrapper [type='checkbox']:focus ~ .rs-checkbox-inner::before {
    -webkit-box-shadow: 0 0 0 2px var(--colour-74) !important;
    box-shadow: 0 0 0 2px var(--colour-74) !important;
}
.rs-checkbox-checked .rs-checkbox-wrapper [type='checkbox']:focus ~ .rs-checkbox-inner::before {
    -webkit-box-shadow: 0 0 0 2px var(--colour-74) !important;
    box-shadow: 0 0 0 2px var(--colour-74) !important;
}

.rs-nav-default .rs-nav-item:not(.rs-nav-item-active):not(.rs-nav-item-disabled) > .rs-nav-item-content:active,
.rs-nav-default .rs-nav-item:not(.rs-nav-item-active):not(.rs-nav-item-disabled) > .rs-nav-item-content:hover,
.rs-nav-default .rs-nav-item:not(.rs-nav-item-active):not(.rs-nav-item-disabled) > .rs-nav-item-content:focus,
.rs-btn-default:hover,
.rs-btn-default:focus,
.rs-btn-subtle:active,
.rs-btn-subtle.rs-btn-active,
.rs-open > .rs-dropdown-toggle.rs-btn-subtle,
.rs-btn-subtle:active:hover,
.rs-btn-subtle.rs-btn-active:hover,
.rs-open > .rs-dropdown-toggle.rs-btn-subtle:hover,
.rs-btn-subtle:active:focus,
.rs-btn-subtle.rs-btn-active:focus,
.rs-open > .rs-dropdown-toggle.rs-btn-subtle:focus,
.rs-btn-subtle:active.focus,
.rs-btn-subtle.rs-btn-active.focus,
.rs-open > .rs-dropdown-toggle.rs-btn-subtle.focus {
    background: var(--colour-10);
}

h1, h2, h3, h4, h5, .card {
  color: var(--text-colour) !important;
}

.rs-notification-title {
    color: var(--text-colour) !important;
}

.rs-notification-item-content,
.rs-modal-content,
.rs-modal-content .rs-list-item,
.rs-picker-menu {
    background-color: var(--dropdown-background);
}

@media only screen and (max-width: 1024px) {
    .container {
        padding-left: 1rem;
        padding-right: 1rem;
    }
}

.rs-modal {
    max-width: 95vw;
    max-height: 80vh;
}

.rs-nav-waterline {
    border-top-color: var(--colour-5) !important;
}
